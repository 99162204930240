.maintenance-container {
    color: #fff;
    height: 100vh;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    background: transparent var(--gradient-background);

    h2 {
        color: #fff;
        font-size: 24px;
        margin-top: 20px;
        margin-bottom: 10px;
    }

    span {
        font-size: 16px;
        color: #FFFFFF7F;
    }

    &>span:nth-child(4) {
        margin-bottom: 10px;
    }

    #big-bottom-circle {
        fill: #455d7b;
    }

    #big-bottom-gear, #path264 {
        fill: #8e97b5;
    }

    #little-bottom-circle {
        fill: #687b93;
    }

    #right-bottom-gear{
        fill: #375073;
    }

    #right-top-gear {
        fill: #4f6484;
    }

    #left-top-little-gaer {
        fill: #1b3b5e;
    }

    #left-top-big-gear {
        fill: #667993;
    }
}