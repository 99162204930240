.loader-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 120px;
}

.invite-user-btn {
  background: var(--gradient-button-hover) !important;
  color: #fff !important;

  .fa-send::before, .fa-paper-plane::before {
    color: #fff !important;
  }
}