@mixin backgroundBlur {
  backdrop-filter: blur(11px) brightness(103%);
  background: url(./assets/images/backgroundBlur.png);
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;
}

.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-title {
  font-size: 1.5em;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.sub-user-photo {
  max-width: 300px;
}

:-webkit-autofill {
  transition-delay: 9999s
}