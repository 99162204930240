:root {
  --primary-color: #8088F0;
  --gradient-button-hover: linear-gradient(107deg, #8087e9 0%, #ab8ddf 100%);
  --gradient-background: linear-gradient(180deg, #052342 0%, #1F3960 50%, #6F5D7F 100%);
  --secondary-color: #fff;
  --error-color: #f4516c;
  --success-color: #4ccd37;
  --dark-color: #888;
  --light-dark-color: #959ba2;
}
