@media (orientation: portrait) {
  .prediction-chart-height {
    height: 50vh !important;
  }
}

@media (orientation: landscape) {
  .prediction-chart-height {
    height: 100vh !important;
    background: transparent var(--gradient-background);
  }
}