.sensorData-tooltip {
  background: #04182e !important;
  max-width: 500px !important;
}

.sensorData-popper {
  border-radius: 6px;
  background: #04182e;
}

.sensorData-container,
.sensorData-container-car-charging,
.sensorData-container-water-heater {
  padding: 6px 12px;
  display: flex;
  gap: 20px;
  align-items: center;
  margin-top: -10px;
  background: #04182e;

  .sensorData-title,
  .sensorData-value {
    white-space: nowrap;
    display: flex;
    flex-direction: column;
    font-size: 14px;
  }

  .sensorData-title {
    color: #8f97b6;
  }

  .sensorData-value {
    color: #fff;
    align-items: flex-end;
  }
}

.sensorData-container-water-heater {
  padding: 0px;
  padding-right: 10px;
}

.sensorData-container-car-charging {
  width: 260px;

  .sensorData-value {
    width: 110px;
  }
}