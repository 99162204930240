@import './../../App.scss';

.display-none {
  display: none !important;
}

span.thank_you_email {
  color: var(--primary-color);
  cursor: pointer;
}

.thank_you_email-container {
  .thank_you_email-content-parent {

    @include backgroundBlur;

    border-radius: 20px;
    padding: 50px 60px;

    .thank_you_email-content {
      min-width: 100%;
    }
  }
}